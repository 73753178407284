const basePath = '/v1';
const searchListPath = `${basePath}/searches`;

export default {
	missingPersons: {
		events: {
			retrieve: `${basePath}/events`,
		},
		searches: {
			retrieve: searchListPath,
			create: searchListPath,
			update: `${basePath}/searches/{searchId}`,
			createAppendage: `${basePath}/searches/{searchId}/appendages`,
			uploadDocument: `{presignedUrl}`,
			finalizeUpload: `${basePath}/searches/{searchId}/appendages/{appendageId}/finalize-upload`,
			retrieveAppendageErrors: `${basePath}/searches/{searchId}/appendages/{appendageId}/errors`,
		},
		reports: {
			download: `${basePath}/searches/{searchId}/reports/download`,
			generate: `${basePath}/searches/{searchId}/reports/generate`,
		},
		configurations: {
			retrieve: `${basePath}/configurations`,
		},
	},
};
