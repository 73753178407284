import get from 'lodash/get';
import downloadFile from '@audacious/web-common/utilities/downloadFile';
import showNotification from '../../../actions/show-notification';

function beforeRequest(actionContext, { options }) {
	const { searchId } = options;

	actionContext.dispatch('DOWNLOAD_REPORT_START', searchId);
}

function onSuccess(actionContext, { response: { data }, options }) {
	const { searchName, reportTime, searchId } = options;

	const sessionStore = actionContext.getStore('Session');
	const org = sessionStore.getOrganization();

	const fileName = `${org.getName()}_${searchName}_Report_${reportTime}.csv`;
	downloadFile(data, fileName, 'text/csv');

	actionContext.dispatch('DOWNLOAD_REPORT_FINISH', searchId);
}

function onFailed(actionContext, { options }) {
	const { searchId } = options;

	actionContext.executeAction(showNotification, {
		heading: 'Report Download Error',
		message: 'Unable to download report. Please try again.',
		color: 'danger',
	});

	actionContext.dispatch('DOWNLOAD_REPORT_FAILED', searchId);
}

export default (config) => ({
	serviceName: 'downloadReport',
	config: {
		method: 'GET',
		url: get(config, 'endpoints.missingPersons.reports.download'),
	},
	beforeRequest,
	onSuccess,
	onFailed,
	onError: onFailed,
	onTimeout: onFailed,
	onFatal: onFailed,
});
