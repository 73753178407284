const items = [
	{
		id: '309e3dc9-5050-46dd-82b9-6f30db29a721',
		title: 'Completed Mock',
		description: 'Search description',
		eventId: '02308326-f793-4353-ae65-b0dee19eddaa',
		organizationId: '7dc38e0a-f52a-4edc-844d-c0bf98b661a4',
		tenantId: 'APPTEST',
		createdBy: '54587f41-fc68-4087-afb0-efd5de45ffaf',
		updatedBy: '54587f41-fc68-4087-afb0-efd5de45ffaf',
		createdAt: '2023-04-25T19:42:37.176Z',
		updatedAt: '2023-04-25T19:42:37.176Z',
		displayedStatus: 'Completed',
		status: 'ACTIVE',
		appendages: [
			{
				id: '11111',
				s3Id: '11111',
				searchId: '12345',
				createdBy: 'User 1',
				updatedBy: 'User 1',
				createdAt: '2004-10-19 10:23:54',
				updatedAt: '2004-10-19 10:23:54',
				processedAt: '2004-10-19 10:23:54',
				status: 'complete',
			},
		],
		completedExecution: {
			id: '58386ed2-187e-48f5-b80a-3cd9a7063004',
			searchId: '309e3dc9-5050-46dd-82b9-6f30db29a723',
			status: 'COMPLETED',
			numFound: 12900,
			numDemographics: 39890,
			startedAt: '2023-04-25T19:44:11.859Z',
			reportGeneratedAt: '2023-04-25T19:45:07.399Z',
		},
	},
	{
		id: '309e3dc9-5050-46dd-82b9-6f30db29a722',
		title: 'Error Mock',
		description: 'Search description',
		eventId: '02308326-f793-4353-ae65-b0dee19eddaa',
		organizationId: '7dc38e0a-f52a-4edc-844d-c0bf98b661a4',
		tenantId: 'APPTEST',
		createdBy: '54587f41-fc68-4087-afb0-efd5de45ffaf',
		updatedBy: '54587f41-fc68-4087-afb0-efd5de45ffaf',
		createdAt: '2023-04-25T19:42:37.176Z',
		updatedAt: '2023-04-25T19:42:37.176Z',
		displayedStatus: 'Error',
		status: 'ACTIVE',
		appendages: [
			{
				id: '11111',
				s3Id: '11111',
				searchId: '12345',
				createdBy: 'User 1',
				updatedBy: 'User 1',
				createdAt: '2004-10-19 10:23:54',
				updatedAt: '2004-10-19 10:23:54',
				processedAt: '2004-10-19 10:23:54',
				status: 'complete',
			},
		],
		completedExecution: {
			id: '58386ed2-187e-48f5-b80a-3cd9a7063004',
			searchId: '309e3dc9-5050-46dd-82b9-6f30db29a723',
			status: 'ERROR',
			numFound: 12900,
			numDemographics: 39890,
			startedAt: '2023-04-25T19:44:11.859Z',
			reportGeneratedAt: '2023-04-25T19:45:07.399Z',
		},
	},
	{
		id: '309e3dc9-5050-46dd-82b9-6f30db29a723',
		title: 'SEARCHING Mock',
		description: 'Search description',
		eventId: '02308326-f793-4353-ae65-b0dee19eddaa',
		organizationId: '7dc38e0a-f52a-4edc-844d-c0bf98b661a4',
		tenantId: 'APPTEST',
		createdBy: '54587f41-fc68-4087-afb0-efd5de45ffaf',
		updatedBy: '54587f41-fc68-4087-afb0-efd5de45ffaf',
		createdAt: '2023-04-25T19:42:37.176Z',
		updatedAt: '2023-04-25T19:42:37.176Z',
		displayedStatus: 'Searching',
		status: 'ACTIVE',
		appendages: [
			{
				id: '11111',
				s3Id: '11111',
				searchId: '12345',
				createdBy: 'User 1',
				updatedBy: 'User 1',
				createdAt: '2004-10-19 10:23:54',
				updatedAt: '2004-10-19 10:23:54',
				processedAt: '2004-10-19 10:23:54',
				status: 'complete',
			},
		],
		completedExecution: {
			id: '58386ed2-187e-48f5-b80a-3cd9a7063004',
			searchId: '309e3dc9-5050-46dd-82b9-6f30db29a723',
			status: 'SEARCHING',
			numFound: 12900,
			numDemographics: 39890,
			startedAt: '2023-04-25T19:44:11.859Z',
			reportGeneratedAt: '2023-04-25T19:45:07.399Z',
		},
	},
	{
		id: '309e3dc9-5050-46dd-82b9-6f30db29a724',
		title: 'Paused Mock',
		description: 'Search description',
		eventId: '02308326-f793-4353-ae65-b0dee19eddaa',
		organizationId: '7dc38e0a-f52a-4edc-844d-c0bf98b661a4',
		tenantId: 'APPTEST',
		createdBy: '54587f41-fc68-4087-afb0-efd5de45ffaf',
		updatedBy: '54587f41-fc68-4087-afb0-efd5de45ffaf',
		createdAt: '2023-04-25T19:42:37.176Z',
		updatedAt: '2023-04-25T19:42:37.176Z',
		displayedStatus: 'Paused',
		status: 'PAUSED',
		appendages: [
			{
				id: '11111',
				s3Id: '11111',
				searchId: '12345',
				createdBy: 'User 1',
				updatedBy: 'User 1',
				createdAt: '2004-10-19 10:23:54',
				updatedAt: '2004-10-19 10:23:54',
				processedAt: '2004-10-19 10:23:54',
				status: 'complete',
			},
		],
		completedExecution: {
			id: '58386ed2-187e-48f5-b80a-3cd9a7063004',
			searchId: '309e3dc9-5050-46dd-82b9-6f30db29a723',
			status: 'COMPLETED',
			numFound: 12900,
			numDemographics: 39890,
			startedAt: '2023-04-25T19:44:11.859Z',
			reportGeneratedAt: '2023-04-25T19:45:07.399Z',
		},
	},
];

export default function retrieveSearches({ resolve }) {
	setTimeout(() => {
		resolve(items);
	}, 500);
}
