import get from 'lodash/get';
import '../../../styles.scss';

function beforeRequest(actionContext) {
	actionContext.dispatch('RETRIEVE_APPENDAGE_ERRORS_START');
}

function onSuccess(actionContext, { response, options = {} }) {
	const sessionStore = actionContext.getStore('Session');
	const org = sessionStore.getOrganization();

	actionContext.dispatch('RETRIEVE_APPENDAGE_ERRORS_FINISH', {
		data: response.data,
		...options,
		organizationName: org.getName(),
	});
}

function onFailed(actionContext, { response, error }) {
	const errorRes = response ? response.statusText : error;

	actionContext.dispatch('RETRIEVE_APPENDAGE_ERRORS_FAILED', errorRes);
}

export default (config) => ({
	serviceName: 'retrieveAppendageErrors',
	config: {
		method: 'GET',
		url: get(config, 'endpoints.missingPersons.searches.retrieveAppendageErrors'),
	},
	beforeRequest,
	onSuccess,
	onFailed,
	onError: onFailed,
	onTimeout: onFailed,
	onFatal: onFailed,
});
