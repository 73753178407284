const NAME_PATTERN = /^(?!\s)(?![\s\S]*\s$)^[A-Za-z0-9!@#$%^&*()+;:'",.<>?\s\-']*$/;
const CONTAINS_LETTER_PATTERN = /[a-zA-Z]+/;
const MIN_CHARACTERS = 8;
const MAX_CHARACTERS = 50;
const INVALID_VALUE_MSG = 'Enter a valid value';
const INVALID_CHARACTERS_MSG = 'Enter valid characters';
const MUST_CONTAIN_LETTER_MSG = 'Must contain at least 1 letter';
const MIN_VALUE_MSG = `This field cannot be shorter than ${MIN_CHARACTERS} characters`;
const MAX_VALUE_MSG = `This field cannot be longer than ${MAX_CHARACTERS} characters`;

export default {
	NAME_PATTERN,
	MIN_CHARACTERS,
	MAX_CHARACTERS,
	INVALID_VALUE_MSG,
	INVALID_CHARACTERS_MSG,
	MIN_VALUE_MSG,
	MAX_VALUE_MSG,
	CONTAINS_LETTER_PATTERN,
	MUST_CONTAIN_LETTER_MSG,
};
