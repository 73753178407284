import get from 'lodash/get';
import showNotification from '../../../actions/show-notification';

function beforeRequest(actionContext) {
	actionContext.dispatch('UPDATE_SEARCH_START');
}

function onSuccess(actionContext, { response: { data } }) {
	const ConfigSettingsStore = actionContext.getStore('ConfigSettingsStore');
	const configSettings = ConfigSettingsStore.getConfigSettings();

	actionContext.dispatch('UPDATE_SEARCH_FINISH', { data, configSettings });
}

function onFailed(actionContext, { response, error }) {
	actionContext.executeAction(showNotification, {
		heading: 'Error Updating Search',
		message: 'Unable to update search. Please try again',
		color: 'danger',
	});

	const errorRes = response ? response.statusText : error;

	actionContext.dispatch('UPDATE_SEARCH_FAILED', errorRes);
}

export default (config) => ({
	serviceName: 'updateSearch',
	config: {
		method: 'PUT',
		url: get(config, 'endpoints.missingPersons.searches.update'),
	},
	beforeRequest,
	onSuccess,
	onFailed,
	onError: onFailed,
	onTimeout: onFailed,
	onFatal: onFailed,
});
