import get from 'lodash/get';
import showNotification from '../../../actions/show-notification';

function beforeRequest(actionContext) {
	actionContext.dispatch('CREATE_SEARCH_START');
}

function onSuccess(actionContext, { response: { data } }) {
	const ConfigSettingsStore = actionContext.getStore('ConfigSettingsStore');
	const configSettings = ConfigSettingsStore.getConfigSettings();

	actionContext.dispatch('CREATE_SEARCH_FINISH', { data, configSettings });
}

function onFailed(actionContext, { response, error }) {
	actionContext.executeAction(showNotification, {
		heading: 'Error Adding Search List',
		message: 'Unable to add search list. Please try again',
		color: 'danger',
	});

	const errorRes = response ? response.statusText : error;

	actionContext.dispatch('CREATE_SEARCH_FAILED', errorRes);
}

export default (config) => ({
	serviceName: 'createSearch',
	config: {
		method: 'POST',
		url: get(config, 'endpoints.missingPersons.searches.create'),
	},
	beforeRequest,
	onSuccess,
	onFailed,
	onError: onFailed,
	onTimeout: onFailed,
	onFatal: onFailed,
});
