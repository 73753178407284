import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import find from 'lodash/find';
import CommonStore from '@audacious/web-common/fluxible/CommonStore';
import downloadFile from '@audacious/web-common/utilities/downloadFile';
import SearchExecutionStatus from '@missing-persons/common/src/common/search-execution-status';

/* eslint-disable import/no-extraneous-dependencies */
import { format } from 'date-fns';

const initialState = {
	searches: [],
	isSaving: false,
	hasEverReceivedData: false,
	error: null,
	presignedUrl: null,
	appendageId: null,
	unmodifiedSearches: [],
	appendageErrors: {
		isDownloading: false,
		error: null,
	},
};

function formatCompletedExecution(completedExecution, showReportDownloadIcon) {
	return {
		reportGeneratedAt: completedExecution?.reportGeneratedAt
			? format(new Date(completedExecution.reportGeneratedAt), 'MM/dd/yyyy h:mm a')
			: null,
		showReportDownloadIcon,
	};
}

function formatSearch(configSettings, search) {
	const { enableCMTIntegration } = configSettings;
	let completedExecution = get(search, 'completedExecution');

	const appendages = map(search.appendages, (appendage, index) => ({
		...appendage,
		formatted: {
			createdAt: format(new Date(appendage.createdAt), 'MM/dd/yyyy h:mm a'),
			fileNumber: index + 1,
		},
	}));

	if (enableCMTIntegration) {
		completedExecution = isEmpty(completedExecution) ? {} : completedExecution;
		if (!isEmpty(appendages)) {
			completedExecution.formatted = formatCompletedExecution(completedExecution, true);
		}
	} else if (
		!isEmpty(completedExecution) &&
		completedExecution?.status === SearchExecutionStatus.COMPLETED
	) {
		completedExecution.formatted = formatCompletedExecution(completedExecution, true);
	}

	return {
		...search,
		disableReportGeneration:
			enableCMTIntegration &&
			(search.displayedStatus === 'Searching' || search.displayedStatus === 'Paused'),
		appendages,
		completedExecution,
	};
}

class SearchStore extends CommonStore {
	constructor(dispatcher) {
		super(dispatcher, initialState);
	}

	getPresignedUrl() {
		const { presignedUrl } = this.state;
		return presignedUrl;
	}

	searchRequestStart() {
		const { error } = this.state;

		if (error) {
			this.setState({ error: null });
		}
	}

	searchRequestFinish(response) {
		const { data: newSearches, configSettings } = response;
		const { searches: existingSearches, unmodifiedSearches, hasEverReceivedData } = this.state;

		if (isEqual(newSearches, unmodifiedSearches) && hasEverReceivedData) {
			return;
		}

		const unProcessedSearches = cloneDeep(newSearches);
		const formatedSearches = map(newSearches, (newSearch) => {
			const existingSearch = existingSearches.find((ps) => ps.id === newSearch.id);

			// Utilizing the existing search to maintain the  report generation status.
			return formatSearch(configSettings, { ...existingSearch, ...newSearch });
		});

		this.setState({
			searches: formatedSearches,
			error: null,
			unmodifiedSearches: unProcessedSearches,
			hasEverReceivedData: true,
		});
	}

	searchRequestFail(error) {
		this.setState({
			error,
		});
	}

	createSearchStart() {
		this.setState({
			isSaving: true,
			error: null,
		});
	}

	createSearchFinish(response) {
		const { data: newSearch, configSettings } = response;
		const { searches } = this.state;

		const formattedSearch = formatSearch(configSettings, newSearch);
		formattedSearch.displayedStatus = 'Awaiting Records';
		searches.unshift(formattedSearch);

		this.setState({
			searches,
			isSaving: false,
			error: null,
		});
	}

	createSearchFail(error) {
		this.setState({
			isSaving: false,
			error,
		});
	}

	createAppendageStart() {
		this.setState({
			isSaving: true,
			error: null,
		});
	}

	createAppendageFinish(response) {
		const { data: appendage, configSettings } = response;
		const { presignedUrl, id } = appendage;
		const { searches } = this.state;

		const searchIndex = findIndex(searches, { id: appendage.searchId });
		const search = searches[searchIndex];
		search.appendages.push(appendage);

		if (configSettings.enableCMTIntegration) {
			search.displayedStatus = 'Searching';
		}

		const formattedSearch = formatSearch(configSettings, search);
		searches.splice(searchIndex, 1, formattedSearch);

		this.setState({
			searches,
			presignedUrl,
			appendageId: id,
			isSaving: false,
			error: null,
		});
	}

	createAppendageFail(error) {
		this.setState({
			isSaving: false,
			error,
		});
	}

	uploadDocumentFail(error) {
		this.setState({
			error,
		});
	}

	finalizeUploadFinish(response) {
		const { searchId, configSettings } = response;

		if (!configSettings.enableCMTIntegration) {
			return;
		}

		const { searches } = this.state;

		const search = find(searches, (s) => s.id === searchId);

		if (search) {
			search.disableReportGeneration = true;
		}

		this.setState({ searches });
	}

	updateSearchStart() {
		this.setState({
			isSaving: true,
			error: null,
		});
	}

	updateSearch(response) {
		const { data: search, configSettings } = response;
		const { searches } = this.state;
		const searchIdx = findIndex(searches, (s) => s.id === search.id);

		const updatedSearch = formatSearch(configSettings, {
			...searches[searchIdx],
			...search,
		});
		searches.splice(searchIdx, 1, updatedSearch);

		this.setState({
			searches,
			error: null,
			isSaving: false,
		});
	}

	updateSearchFail(error) {
		this.setState({
			isSaving: false,
			error,
		});
	}

	retrieveAppendageErrorsStart() {
		this.setState({
			appendageErrors: {
				isDownloading: true,
				error: null,
			},
		});
	}

	retrieveAppendageErrorsFinish({ data, searchId, appendageId, organizationName }) {
		const { searches } = this.state;
		const search = find(searches, { id: searchId });
		const { appendages } = search;
		const appendage = find(appendages, { id: appendageId });

		const fileName = `${organizationName}_${search.title}_Error_${appendage.formatted.fileNumber}.csv`;
		downloadFile(data, fileName, 'text/csv');
		this.setState({
			appendageErrors: {
				isDownloading: false,
				error: null,
			},
		});
	}

	retrieveAppendageErrorsFail(error) {
		this.setState({
			appendageErrors: {
				error: !isEmpty(error) ? error : 'Unknown error',
				isDownloading: false,
			},
		});
	}

	downloadReportStart(searchId) {
		const { searches } = this.getState();

		const searchIndex = findIndex(searches, { id: searchId });

		if (searchIndex >= 0) {
			const search = searches[searchIndex];
			search.isDownloadingReport = true;
		}

		this.setState({ searches });
	}

	downloadReportComplete(searchId) {
		const { searches } = this.getState();

		const searchIndex = findIndex(searches, { id: searchId });

		if (searchIndex >= 0) {
			const search = searches[searchIndex];
			search.isDownloadingReport = false;
		}

		this.setState({ searches });
	}

	generateReportStart(searchId) {
		const { searches } = this.getState();

		const search = find(searches, { id: searchId });

		search.isGeneratingReport = true;
		search.generateReportStartedAt = Date.now();

		this.setState({ searches });
	}

	resetReportGenerationState(searchId) {
		const { searches } = this.getState();

		const search = find(searches, { id: searchId });

		search.isGeneratingReport = false;
		search.generateReportStartedAt = null;

		this.setState({ searches });
	}

	resetErrorState() {
		this.setState({
			appendageErrors: {
				error: null,
			},
		});
	}
}

SearchStore.storeName = 'SearchStore';
SearchStore.handlers = {
	GET_PRESIGNED_URL: 'getPresignedUrl',
	SEARCH_REQUEST_START: 'searchRequestStart',
	SEARCH_REQUEST_FINISH: 'searchRequestFinish',
	SEARCH_REQUEST_FAILED: 'searchRequestFail',
	CREATE_SEARCH_START: 'createSearchStart',
	CREATE_SEARCH_FINISH: 'createSearchFinish',
	CREATE_SEARCH_FAILED: 'createSearchFail',
	CREATE_APPENDAGE_START: 'createAppendageStart',
	CREATE_APPENDAGE_FINISH: 'createAppendageFinish',
	CREATE_APPENDAGE_FAILED: 'createAppendageFail',
	UPLOAD_DOCUMENT_FAILED: 'uploadDocumentFail',
	FINALIZE_UPLOAD_FINISH: 'finalizeUploadFinish',
	UPDATE_SEARCH_START: 'updateSearchStart',
	UPDATE_SEARCH_FINISH: 'updateSearch',
	UPDATE_SEARCH_FAILED: 'updateSearchFail',
	RETRIEVE_APPENDAGE_ERRORS_FAILED: 'retrieveAppendageErrorsFail',
	RETRIEVE_APPENDAGE_ERRORS_FINISH: 'retrieveAppendageErrorsFinish',
	RETRIEVE_APPENDAGE_ERRORS_START: 'retrieveAppendageErrorsStart',
	DOWNLOAD_REPORT_START: 'downloadReportStart',
	DOWNLOAD_REPORT_FINISH: 'downloadReportComplete',
	DOWNLOAD_REPORT_FAILED: 'downloadReportComplete',
	GENERATE_REPORT_START: 'generateReportStart',
	GENERATE_REPORT_FAILED: 'resetReportGenerationState',
	RESET_REPORT_GENERATION_STATE: 'resetReportGenerationState',
	RESET_ERROR_STATE: 'resetErrorState',
	LOGOUT: 'resetState',
};

export default SearchStore;
