import React from 'react';
import PropTypes from 'prop-types';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import { connectToStores } from 'fluxible-addons-react';
import { withRouter } from 'react-router-dom';
import {
	PageContainer,
	PageTitle,
	// PageContainerGroup,
} from '@audacious/components/components/Page';
import Spinner from '@audacious/components/components/Spinner';
import { Text } from '@audacious/components/components/Typography';
import Button from '@audacious/components/components/Button';
import IconButton from '@audacious/components/components/IconButton';
import LinkButton from '@audacious/components/components/Link';
import { SimpleTable } from '@audacious/components/components/Table';
import TableMessage from '@audacious/components/components/TableMessage';
// import { faMagnifyingGlass } from '@audacious/icons/regular/faMagnifyingGlass';
import { faPencil } from '@audacious/icons/regular/faPencil';
import { faFileCsv } from '@audacious/icons/regular/faFileCsv';

import CreateEditListDrawer from './create-edit-list-drawer/create-edit-list-drawer';
import SearchDetailsDrawer from './search-details-drawer/search-details-drawer';
import createSearch from '../../../actions/create-search';
import updateSearch from '../../../actions/update-search';
import resetErrorState from '../../../actions/download-error-log';
import retrieveConfigSettings from '../../../actions/retrieve-config-settings';
import retrieveSearchesPoller from '../../../common/retrieve-searches-poller';

class SearchesPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			createListDrawerIsOpen: false,
			selectedSearchListId: null,
			searchBeingEdited: null,			
		};

		this.handleCancelCreateNewList = this.handleCancelCreateNewList.bind(this);
		this.handleCreateNewListClick = this.handleCreateNewListClick.bind(this);
		this.handleCreateNewListComplete = this.handleCreateNewListComplete.bind(this);
		this.renderNoSearchListMessage = this.renderNoSearchListMessage.bind(this);
		this.handleSearchDetailsClose = this.handleSearchDetailsClose.bind(this);
		this.handleTryAgainClick = this.handleTryAgainClick.bind(this);
		this.handleEditSearch = this.handleEditSearch.bind(this);
		this.renderEditColumn = this.renderEditColumn.bind(this);
		this.handleCancelEditSearch = this.handleCancelEditSearch.bind(this);
		this.handleEditSearchComplete = this.handleEditSearchComplete.bind(this);
		this.handleReportClick = this.handleReportClick.bind(this);
		this.downloadReport = this.downloadReport.bind(this);
		this.handleResetErrorNotification = this.handleResetErrorNotification.bind(this);
		
		this.COLUMNS = [
			{
				headerBody: 'List Name',
				cellValuePath: 'title',
				sortable: false,
				renderCell: (cellValue, item) => (
					<LinkButton
						id={`search-list-name-link-${item.id}`}
						onClick={() => this.setState({ selectedSearchListId: item.id })}
					>
						{cellValue}
					</LinkButton>
				),
			},
			{
				headerBody: 'Found',
				cellValuePath: 'completedExecution.numDemographics',
				sortable: false,
				width: '140px',
				renderCell: (cellValue, item) => {
					const numFound = item?.completedExecution?.numFound ?? 0;
					const numDemographics = item?.completedExecution?.numDemographics ?? 0;
					if (numDemographics) {
						return (
							<>
								<Text color="success">
									{ numFound }
								</Text>
								{ ' of ' }
								<Text>
									{ numDemographics }
								</Text>
							</>
						);
					}

					return null;
				}
			},
			{
				headerBody: 'Report Date',
				cellValuePath: 'completedExecution.formatted.reportGeneratedAt',
				sortable: false,
				width: '170px',
			},
			{
				headerBody: 'Report',
				cellValuePath: 'completedExecution.formatted.showReportDownloadIcon',
				sortable: false,
				width: '110px',
				renderCell: (cellValue, item) => {
					if (cellValue) {
						if (item?.isDownloadingReport || item?.isGeneratingReport) {
							return <Spinner size="sm" />;
						}

						return (
							<IconButton 
								icon={faFileCsv}
								color="primary"
								size="sm"
								disabled = {item.disableReportGeneration}
								onClick={() => this.handleReportClick(item)}
							/>
						);
					}

					return null;
				}
			},
			{
				headerBody: 'Status',
				cellValuePath: 'displayedStatus',
				sortable: false,
				width: '110px',
			},
			{
				headerBody: '',
				cellValuePath: '',
				sortable: false,
				width: '60px',
				renderCell: this.renderEditColumn,
			},
		];
	}

	componentDidMount() {
		const {
			fluxibleContext: {
				executeAction,
				service: {
					retrieveEvents,
				},
			},
		} = this.props;

		executeAction(retrieveConfigSettings);	

		retrieveEvents();
	}

	componentWillUnmount() {
		retrieveSearchesPoller.stop();
	}

	handleCreateNewListClick() {
		this.setState({ createListDrawerIsOpen: true });
	}

	handleCancelCreateNewList() {
		this.setState({ createListDrawerIsOpen: false });
	}

	handleCreateNewListComplete(data, csvData) {
		this.setState({ createListDrawerIsOpen: false });

		const {
			fluxibleContext: {
				executeAction,
			},
		} = this.props;

		executeAction(createSearch, { data, csvData });
	}

	handleReportClick(item) {
		const {
			fluxibleContext: {
				service: {
					generateReport,
				},
			},
		} = this.props;

		const { enableCMTIntegration } = this.props;

		const searchId = item?.id;

		if (enableCMTIntegration) {			
			generateReport({ options: { searchId } });
		} else {			
			this.downloadReport(item);
		}		   
	}

	handleEditSearchComplete(data, csvData) {
		this.setState({ searchBeingEdited: null });

		const {
			fluxibleContext: {
				executeAction
			},
		} = this.props;

		executeAction(updateSearch, { data, csvData });
	}

	handleEditSearch({ meta }) {
		const search = meta;
		this.setState({ searchBeingEdited: search });
	}

	handleCancelEditSearch() {
		this.setState({
			searchBeingEdited: null,
		});
	}

	handleSearchDetailsClose() {
		this.setState({ selectedSearchListId: null });
	}

	handleResetErrorNotification() {
		const {
			fluxibleContext: {
				executeAction
			},
		} = this.props;

		executeAction(resetErrorState);		
	}

	handleTryAgainClick() {
		const {
			fluxibleContext: {
				service: {
					retrieveSearches,
					retrieveEvents,
				},
			},
		} = this.props;

		retrieveSearches();
		retrieveEvents();
	}

	downloadReport(item) {
		const {
			fluxibleContext: {
				service: {
					downloadReport,
				},
			},
		} = this.props;

		const searchId = item?.id;
		const searchName = item?.title;
		const reportTime = item?.completedExecution?.formatted?.reportGeneratedAt;

		downloadReport({ options: { searchId, searchName, reportTime } });
	}

	// eslint-disable-next-line class-methods-use-this
	renderEditColumn(cellValue, search) {
		return (
			<IconButton
				icon={faPencil}
				size="sm"
				color="primary"
				onClick={this.handleEditSearch}
				onClickMeta={search}
			/>
		);
	}

	renderNoSearchListMessage() {
		const { isLoadingEvents, hasReceivedSearches, isLoadingConfigSettings, error, searches } = this.props;

		if (error) {
			const tryAgainButton = (
				<Button
					size="md"
					color="primary"
					id="searches-empty-table-try-again-button"
					onClick={this.handleTryAgainClick}
				>
					Try Again
				</Button>
			);

			return (
				<TableMessage
					header="Unable to load information"
					body="Please try again."
					footer={tryAgainButton}
				/>
			);
		}

		if (isLoadingEvents || !hasReceivedSearches || isLoadingConfigSettings) {
			return <Spinner size="lg" variant="overlay" />;
		}

		if (isEmpty(searches)) {
			return (
				<TableMessage
					header="No search lists"
					footer={
						<Button
							size="md"
							color="primary"
							id="searches-empty-table-add-new-list-button"
							onClick={this.handleCreateNewListClick}
						>
							Add New List
						</Button>
					}
				/>
			);
		}

		return null;
	}

	render() {
		const { createListDrawerIsOpen, selectedSearchListId, searchBeingEdited } = this.state;

		const { 
			searches,
			events,
			isLoadingEvents,
			hasReceivedSearches,			
			isSaving,					
			error,
			eventError,
			appendageErrors: { isDownloading, error: downloadingError },
		} = this.props;

		let spinnerElement = null;
		let newSearchListButton = null;

		if (isEmpty(error) && !isLoadingEvents && hasReceivedSearches) {
			newSearchListButton = (
				<Button
					id="searches-add-new-list-button"
					variant="outline"
					color="secondary"
					onClick={this.handleCreateNewListClick}
				>
					Add New List
				</Button>
			);
		}

		if (isSaving) {
			spinnerElement = <Spinner size="lg" variant="overlay" />;
		}

		const selectedSearchList = find(searches, { id: selectedSearchListId });		

		return (
			<>
				<PageTitle
					pageName="Missing Persons"
					id="searches-title"
					customFeature={newSearchListButton}
				/>
				<PageContainer asCard allowScroll>
					{/* <PageContainerGroup>
						<Row gutter="16" enableAfter>
							<Column width={['12', '4', '3']}>
								<TextInput
									size="sm"
									placeholder="Search list name"
									leftIcon={{ icon: faMagnifyingGlass }}
									id="searches-search-input"
								/>
							</Column>
						</Row>
					</PageContainerGroup> */}
					<SimpleTable
						id="searches-table"
						// If we have an event error don't show any searches
						items={isEmpty(eventError) ? searches : null}
						columns={this.COLUMNS}
						minWidth={800}
						alwaysShowHeaders
						stickyColumns={1}
						pageTable
						useParentScroll
					/>					
					{(isEmpty(searches) || isEmpty(events)) ? this.renderNoSearchListMessage() : null}
				</PageContainer>
				<CreateEditListDrawer
					open={createListDrawerIsOpen}
					events={events}
					onCancel={this.handleCancelCreateNewList}
					onSaveComplete={this.handleCreateNewListComplete}
					headerText="New List"
					uploadText="NOTE: You can add to the list as needed."
					initialData={null}
				/>
				<CreateEditListDrawer
					open={!isNil(searchBeingEdited)}
					events={events}
					initialData={searchBeingEdited}
					onCancel={this.handleCancelEditSearch}
					onSaveComplete={this.handleEditSearchComplete}
					headerText="Edit List"
					uploadText="NOTE: Uploading a new file will add to the existing list."
				/>
				<SearchDetailsDrawer
					open={!isNil(selectedSearchList)}
					events={events}
					onClose={this.handleSearchDetailsClose}
					searchList={selectedSearchList}
					isDownloading={isDownloading}
					error={downloadingError}
					onNotificationTimeOut={this.handleResetErrorNotification}
				/>		
				{spinnerElement}
			</>
		);
	}
}

SearchesPage.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	searches: PropTypes.arrayOf(PropTypes.object).isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	events: PropTypes.arrayOf(PropTypes.object).isRequired,
	isSaving: PropTypes.bool.isRequired,
	appendageErrors: PropTypes.shape({
		isDownloading: PropTypes.bool.isRequired,
		// eslint-disable-next-line react/forbid-prop-types
		error: PropTypes.any,
	}).isRequired,	
	// eslint-disable-next-line react/forbid-prop-types
	error: PropTypes.any,
	// eslint-disable-next-line react/forbid-prop-types
	eventError: PropTypes.any,
	isLoadingEvents: PropTypes.bool.isRequired,
	hasReceivedSearches: PropTypes.bool.isRequired,
	isLoadingConfigSettings: PropTypes.bool.isRequired,
	enableCMTIntegration: PropTypes.bool.isRequired,
	fluxibleContext: PropTypes.shape({
		executeAction: PropTypes.func.isRequired,
		service: PropTypes.shape({
			retrieveEvents: PropTypes.func.isRequired,
			generateReport: PropTypes.func.isRequired,
			retrieveSearches: PropTypes.func.isRequired,
			downloadReport: PropTypes.func.isRequired,
		}).isRequired,
	}).isRequired,
};

SearchesPage.defaultProps = {
	error: null,
	eventError: null,
};

export default connectToStores(
	withRouter(applyFluxibleContext(SearchesPage)),
	['SearchStore', 'EventStore', 'ConfigSettingsStore'],
	(context) => {
		const searchStore = context.getStore('SearchStore');
		const eventStore = context.getStore('EventStore');
		const configSettingsStore = context.getStore('ConfigSettingsStore');
		const searchState = searchStore.getState();
		const eventState = eventStore.getState();
		const configSettingsState = configSettingsStore.getState();

		return {
			...searchState,
			error: searchState.error ?? eventState.error ?? configSettingsState.error,
			eventError: eventState.error,
			events: eventState.events,
			isLoadingEvents: eventState.isLoading,
			hasReceivedSearches: searchState.hasEverReceivedData,
			isLoadingConfigSettings: configSettingsState.isLoading,
			enableCMTIntegration: configSettingsStore.getEnableCMTIntegration(),			
		};
	},
);
