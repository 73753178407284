import get from 'lodash/get';

function beforeRequest(actionContext) {
	actionContext.dispatch('CONFIG_REQUEST_START');
}

function onSuccess(actionContext, { response: { data } }) {
	actionContext.dispatch('CONFIG_REQUEST_FINISH', data);
}

function onFailed(actionContext, { response, error }) {
	let errorRes = response ? response.statusText : error;
	// If we have an error, but no status text, set a plceholder so the UI responds apporpriately
	if (!errorRes) {
		errorRes = 'Server Error';
	}
	actionContext.dispatch('CONFIG_REQUEST_FAILED', errorRes);
}

export default (config) => ({
	serviceName: 'retrieveConfigSettings',
	config: {
		method: 'GET',
		url: get(config, 'endpoints.missingPersons.configurations.retrieve'),
	},
	beforeRequest,
	onSuccess,
	onFailed,
	onError: onFailed,
	onTimeout: onFailed,
	onFatal: onFailed,
});
