const toLower = require('lodash/toLower');
const includes = require('lodash/includes');
const forEach = require('lodash/forEach');
const { UsaStates } = require('usa-states');

const {
	FIRST_NAME_HEADER,
	MIDDLE_NAME_HEADER,
	LAST_NAME_HEADER,
	GENDER_HEADER,
	ADDRESS_1_HEADER,
	ADDRESS_2_HEADER,
	CITY_HEADER,
	STATE_HEADER,
	ZIP_HEADER,
	PHONE_HEADER,
	SSN_HEADER,
	INSURANCE_HEADER,
	DOB_HEADER,
} = require('./csv-headers');

const singleLetterRegExp = /[a-zA-Z]/;
const singleLetterOrDigitRegExp = /[a-zA-Z0-9]/;

const statesAndTerritories = new UsaStates({ includeTerritories: true });
const validStates = [];

forEach(statesAndTerritories.states, (state) => {
	validStates.push(toLower(state.name));
	validStates.push(toLower(state.abbreviation));
});

function createError(field, value, message) {
	return {
		field,
		value,
		message,
	};
}

function validateWord(name, fieldName, allowDigit = false) {
	const errors = [];

	if (!name) {
		return errors;
	}

	const regex = allowDigit ? singleLetterOrDigitRegExp : singleLetterRegExp;

	if (!regex.test(name)) {
		const message = allowDigit
			? `${fieldName} must contain at least 1 letter or digit`
			: `${fieldName} must contain at least 1 letter`;
		errors.push(createError(fieldName, name, message));
	}

	return errors;
}

const dateRegex = /^(0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])[/-]\d{4}$/;

function validateDOB(dob) {
	const errors = [];

	if (!dob) {
		return errors;
	}

	if (!dateRegex.test(dob)) {
		errors.push(createError(DOB_HEADER, dob, 'DOB is not valid'));
	}

	return errors;
}

const validGenders = ['male', 'female', 'unknown', 'other', 'm', 'f', 'u', 'o'];

function validateGender(gender) {
	const errors = [];

	if (!gender) {
		return errors;
	}

	const lowerGender = toLower(gender);

	if (!includes(validGenders, lowerGender)) {
		errors.push(createError(GENDER_HEADER, gender, 'Gender is not valid'));
	}

	return errors;
}

function validateState(state) {
	const errors = [];

	if (!state) {
		return errors;
	}

	const lowerState = toLower(state);

	if (!includes(validStates, lowerState)) {
		errors.push(createError(STATE_HEADER, state, 'State is not valid'));
	}

	return errors;
}

function validateZipCode(zip) {
	const errors = [];

	if (!zip) {
		return errors;
	}

	const justDigits = zip.replace(/\D/g, '');

	if (justDigits.length !== 5 && justDigits.length !== 9) {
		errors.push(createError(ZIP_HEADER, zip, 'Zip Code must contain 5 or 9 digits'));
	}

	return errors;
}

function validatePhone(phone) {
	const errors = [];

	if (!phone) {
		return errors;
	}

	const justDigits = phone.replace(/\D/g, '');

	if (justDigits.length < 10) {
		errors.push(createError(PHONE_HEADER, phone, 'Phone must contain at least 10 digits'));
	}

	return errors;
}

function validateSSN(ssn) {
	const errors = [];

	if (!ssn) {
		return errors;
	}

	const justDigits = ssn.replace(/\D/g, '');

	if (justDigits.length !== 9 && justDigits.length !== 4) {
		errors.push(createError(SSN_HEADER, ssn, 'SSN must contain 4 or 9 digits'));
	}

	return errors;
}

const GLOBAL_ERROR_FIELD = 'global';
const MINIMUM_FIELDS_ERROR_MESSAGE = 'Missing minimum number of fields.';

function validateDemographic(demographic) {
	const errors = [];

	const firstName = (demographic?.firstName || '').trim();
	errors.push(...validateWord(firstName, FIRST_NAME_HEADER, false));

	const middleName = (demographic?.middleName || '').trim();
	errors.push(...validateWord(middleName, MIDDLE_NAME_HEADER, false));

	const lastName = (demographic?.lastName || '').trim();
	errors.push(...validateWord(lastName, LAST_NAME_HEADER, false));

	const dob = (demographic?.dob || '').trim();
	errors.push(...validateDOB(dob));

	const gender = (demographic?.gender || '').trim();
	errors.push(...validateGender(gender));

	const address1 = (demographic?.addressLine1 || '').trim();
	errors.push(...validateWord(address1, ADDRESS_1_HEADER, true));

	const address2 = (demographic?.addressLine2 || '').trim();
	errors.push(...validateWord(address2, ADDRESS_2_HEADER, true));

	const city = (demographic?.city || '').trim();
	errors.push(...validateWord(city, CITY_HEADER, false));

	const state = (demographic?.state || '').trim();
	errors.push(...validateState(state));

	const zip = (demographic?.zip || '').trim();
	errors.push(...validateZipCode(zip));

	const phone = (demographic?.phone || '').trim();
	errors.push(...validatePhone(phone));

	const ssn = (demographic?.ssn || '').trim();
	errors.push(...validateSSN(ssn));

	const insurance = (demographic?.insurance || '').trim();
	errors.push(...validateWord(insurance, INSURANCE_HEADER, true));

	let hasMinimumRequiredFields = false;

	const hasAddress = (address1 && zip) || (address1 && city && state);

	if (firstName && lastName) {
		if (dob) {
			if (ssn || insurance || hasAddress || phone) {
				hasMinimumRequiredFields = true;
			}
		}

		if (ssn) {
			if (insurance || hasAddress || phone) {
				hasMinimumRequiredFields = true;
			}
		}

		if (insurance) {
			if (phone || hasAddress) {
				hasMinimumRequiredFields = true;
			}
		}
	}

	if (!hasMinimumRequiredFields) {
		errors.push(createError(GLOBAL_ERROR_FIELD, '', MINIMUM_FIELDS_ERROR_MESSAGE));
	}

	return errors;
}

module.exports = { validateDemographic, GLOBAL_ERROR_FIELD, MINIMUM_FIELDS_ERROR_MESSAGE };
