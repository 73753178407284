import React from 'react';
import { AuthenticatedPage } from '@audacious/client';
import SearchesPage from './pages/searches-page';

// eslint-disable-next-line react/prefer-stateless-function
function Root() {
	return (
		<AuthenticatedPage id="missing-persons-page">
			<SearchesPage />
		</AuthenticatedPage>
	);
}

export default Root;
