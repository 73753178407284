import React from 'react';
import PropTypes from 'prop-types';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import Drawer from '@audacious/components/components/Drawer';
import Document, { DocumentHeader, DocumentBody, DocumentFooter } from '@audacious/components/components/Document';
import { Text, SubHeading } from '@audacious/components/components/Typography';
import { Row, Column } from '@audacious/components/components/Grid';
import Button from '@audacious/components/components/Button';
import { SimpleTable } from '@audacious/components/components/Table';
import Chip from '@audacious/components/components/Chip';
import { faFileCsv } from '@audacious/icons/regular/faFileCsv';
import get from 'lodash/get';
import find from 'lodash/find';
import AppendageStatus from '@missing-persons/common/src/common/appendage-status';
import NotificationMessage from '@audacious/components/components/NotificationMessage';
import './search-details-drawer.scss';

class SearchDetailsDrawer extends React.Component {
	constructor(props) {
		super(props);

		this.handleClose = this.handleClose.bind(this);
		this.handleDownloadErrorLogClick = this.handleDownloadErrorLogClick.bind(this);
		this.renderError = this.renderError.bind(this);

		this.COLUMNS = [
			{
				headerBody: 'File Number',
				cellValuePath: 'formatted.fileNumber',
				width: '110px',
			},
			{
				headerBody: 'Username',
				cellValuePath: 'createdByName',
				width: '150px',
			},
			{
				headerBody: 'Date',
				cellValuePath: 'formatted.createdAt',
				width: '200px',
			},
			{
				headerBody: '# of Demographics',
				cellValuePath: 'numberOfDemographics',
				width: '150px',
			},
			{
				headerBody: '# of Errors',
				cellValuePath: 'numberOfErrors',
				width: '95px',
			},
			{
			headerBody: 'Error Log',
			cellValuePath: ['searchId', 'id', 'numberOfErrors'],
			width: '85px',
			renderCell: ([searchId, id, numberOfErrors]) => {			
				if (numberOfErrors > 0) {
					return (
						<Button
							color="danger"
							variant="none"
							leftIcon={faFileCsv}
							size="2xs"
							onClick={this.handleDownloadErrorLogClick}                            
							onClickMeta={{
								searchId,
								id,
							}}
						/>                        
					);
				}
				return '';
			}},
			{
				headerBody: 'File Status',
				cellValuePath: 'status',
				width: '105px',
				renderCell: (cellValue) => {
					let color = 'grey';
					let cellText = cellValue;
					switch (cellValue) {
						case AppendageStatus.READY: {
							cellText = 'Complete'
							color = 'success';
							break;
						}
						case AppendageStatus.ERROR: {
							cellText = 'Error'
							color = 'danger';
							break;
						}
						case AppendageStatus.PENDING: {
							cellText = "In Progress"
							color = 'orange';
							break;
						}
						case AppendageStatus.UPLOADED: {
							cellText = "In Progress"
							color = 'orange';
							break;
						}
						default:
							break;
					}
		
					return <Chip color={color}>{cellText}</Chip>;
				},
			},
		];
	}

	handleClose() {
		const { onClose } = this.props;

		onClose();
	}

	handleDownloadErrorLogClick({ meta }) {
        const {
			fluxibleContext: {
				service: {
					retrieveAppendageErrors,
				},
			},
		} = this.props;

        const { id, searchId } = meta;

        retrieveAppendageErrors({
			options: { searchId, appendageId: id },            
        });
    }

	// eslint-disable-next-line class-methods-use-this
	renderError() {		

		const { error, onNotificationTimeOut } = this.props;

		if (error) {

			setTimeout(() => {
				onNotificationTimeOut();
			 }, 3000)

			return (
				<NotificationMessage
					className="error-log-notification-message"
					id="error-log-notification-message"
					showIcon
					color="danger"
					heading="Error Downloading Appendage Errors"
					message="Unable to download information. Please try again."
				/>		
			)
		}
		return null;
	}

	render() {

		const { open, searchList, isDownloading, events } = this.props;

		const event = find(events, (e) => e.id === searchList?.eventId);		

		const description = get(searchList, 'description');
		const descriptionRender = description ? (
			<Row>
				<Column width="12">
					<Text className="search-list-description">{description}</Text>
				</Column>
			</Row>
		) : null;

		return (		
			<>	
			<Drawer
				open={open}
				size="xl"
				variant="right"
				id="search-details-drawer"
				enableAnimation
				enableCloseButton
				onClose={this.handleClose}
				isLoading={isDownloading}
			>				
				<Document size="xl">
					<DocumentHeader>
						<SubHeading id="search-list-details-list-name" level="4">
							{get(searchList, 'title', '')}
						</SubHeading>
					</DocumentHeader>
					<DocumentBody>
						{descriptionRender}
						<Row gutter="16" enableAfter>
							<Column width="12">
								<Text weight="bold" className="search-list-event-label">
									{'Event: '}
								</Text>
								<Text className="search-list-event-value">
									{event?.name || 'None Specified'}
								</Text>
							</Column>
						</Row>
						<SimpleTable
							id="search-list-appendages-table"
							items={get(searchList, 'appendages', [])}
							columns={this.COLUMNS}
							alwaysShowHeaders
						/>
					</DocumentBody>
					<DocumentFooter>
						<Button
							color="primary"
							variant="opaque"
							id="search-details-drawer-close-footer-button"
							focusOnMount
							onClick={this.handleClose}
						>
							Close
						</Button>
					</DocumentFooter>					
				</Document>												
			</Drawer>	
			{this.renderError()}
			</>					
		);
	}
}

SearchDetailsDrawer.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onNotificationTimeOut: PropTypes.func.isRequired,
	searchList: PropTypes.shape({
		title: PropTypes.string.isRequired,
		description: PropTypes.string,
		eventId: PropTypes.string,
	}),
	events: PropTypes.arrayOf(
		PropTypes.shape({		
			name: PropTypes.string,
			id: PropTypes.string,
		}),
	),
	isDownloading: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	error: PropTypes.any,
	fluxibleContext: PropTypes.shape({
		service: PropTypes.shape({
			retrieveAppendageErrors: PropTypes.func.isRequired,
		}).isRequired,
	}).isRequired,
};

SearchDetailsDrawer.defaultProps = {
	searchList: null,
	events: null,
	error: null,
};

export default applyFluxibleContext(SearchDetailsDrawer);
