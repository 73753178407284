import merge from 'lodash/merge';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import endpoints from '../configs/endpoints';
import retrieveEvents from './events/retrieve-events';
import retrieveSearches from './searches/retrieve-searches';
import createSearch from './searches/create-search';
import createAppendage from './searches/create-appendage';
import uploadDocument from './searches/upload-document';
import finalizeUpload from './searches/finalize-upload';
import updateSearch from './searches/update-search';
import retrieveAppendageErrors from './searches/retrieve-appendage-errors';
import downloadReport from './searches/download-report';
import generateReport from './searches/generate-report';
import retrieveConfigSettings from './config-settings/retrieve-config-settings';

const DEFAULT_CONFIG = {
	endpoints,
};

export default (config) => {
	const configuration = merge(DEFAULT_CONFIG, config);

	const serviceConfigs = [
		retrieveEvents(configuration),
		retrieveSearches(configuration),
		createSearch(configuration),
		createAppendage(configuration),
		uploadDocument(configuration),
		finalizeUpload(configuration),
		updateSearch(configuration),
		retrieveAppendageErrors(configuration),
		downloadReport(configuration),
		generateReport(configuration),
		retrieveConfigSettings(configuration),
	];

	const baseUrlOverride = process.env.BASE_URL_OVERRIDE;

	if (!isEmpty(baseUrlOverride)) {
		return map(serviceConfigs, (serviceConfig) => ({
			...serviceConfig,
			config: {
				...serviceConfig.config,
				baseUrl: isNil(serviceConfig.config.baseUrl)
					? baseUrlOverride
					: serviceConfig.config.baseUrl,
			},
		}));
	}

	return serviceConfigs;
};
