import merge from 'lodash/merge';
import retrieveSearches from './retrieve-searches';
import downloadReport from './download-report';

const DEFAULT_MOCK_SERVICES = {
	retrieveSearches,
	downloadReport,
};

export default ({
	enabled = false,
	// include = [],
	exclude = [],
	immediate = true,
	services = {},
}) => ({
	enabled,
	include: [
		// 'retrieveSearches',
		// 'downloadReport'
	],
	exclude,
	immediate,
	services: merge(DEFAULT_MOCK_SERVICES, services),
	// db: {
	// 	hasClickedExport: false,
	// 	exportFileName: '',
	// 	setHasClickedExport(hasClickedExport) {
	// 		this.hasClickedExport = hasClickedExport;
	// 	},
	// 	setExportFileName(name) {
	// 		this.exportFileName = name;
	// 	},
	// },
});
