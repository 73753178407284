import isNil from 'lodash/isNil';
import uploadAndFinalize from './upload-and-finalize';
import showNotification from './show-notification';

export default async function createSearch(context, { data, csvData }) {
	const searchStore = context.getStore('SearchStore');
	const { service } = context;
	const { title, eventId, description } = data;
	const successMessage = 'Search List Created Successfully';

	service.createSearch(
		{
			data: {
				title,
				eventId,
				description,
			},
		},
		() => {
			const {
				error,
				searches: [{ id }],
			} = searchStore.getState();

			if (isNil(error) && !isNil(csvData)) {
				uploadAndFinalize(context, { id, csvData, successMessage });
			} else if (isNil(error)) {
				showNotification(context, {
					heading: successMessage,
					color: 'success',
				});
			}
		},
	);
}
