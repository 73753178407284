import get from 'lodash/get';
import set from 'lodash/set';

import serviceConfigs from './service-configs';

import mock from './mock';

export const createServiceConfiguration = (config) => ({
	global: get(config, 'global', {}),
	serviceConfigs: serviceConfigs(config),
});

export const createMockServiceConfiguration = (config) => {
	const services = {};

	if (get(config, 'mock.enabled', false) || get(config, 'mock.include', [].length > 0)) {
		set(services, 'mock', mock(get(config, 'mock', {})));
	}

	return services;
};
