import get from 'lodash/get';
import showNotification from '../../../actions/show-notification';

function beforeRequest(actionContext) {
	actionContext.dispatch('FINALIZE_UPLOAD_START');
}

function onSuccess(actionContext, { options: { searchId } }) {
	const ConfigSettingsStore = actionContext.getStore('ConfigSettingsStore');
	const configSettings = ConfigSettingsStore.getConfigSettings();

	actionContext.dispatch('FINALIZE_UPLOAD_FINISH', { searchId, configSettings });
}

function onFailed(actionContext, { response }) {
	actionContext.executeAction(showNotification, {
		heading: 'Error Uploading Document',
		message: 'Unable to upload document. Please try again',
		color: 'danger',
	});

	actionContext.dispatch('FINALIZE_UPLOAD_FAILED', response.statusText);
}

export default (config) => ({
	serviceName: 'finalizeUpload',
	config: {
		method: 'POST',
		url: get(config, 'endpoints.missingPersons.searches.finalizeUpload'),
	},
	beforeRequest,
	onSuccess,
	onFailed,
	onError: onFailed,
	onTimeout: onFailed,
	onFatal: onFailed,
});
