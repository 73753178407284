import boilerplate from '../../localization';

export default {
	version: '0.0.1',
	schema: '0.0.1',
	locale: 'en-US',
	configuration: {
		client: {},
		boilerplate,
	},
};
