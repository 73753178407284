import get from 'lodash/get';

function retrieveEventsBeforeRequest(context) {
	context.dispatch('EVENT_REQUEST_START');
}

function retrieveEventsSuccess(context, { response }) {
	context.dispatch('EVENT_REQUEST_FINISH', response ? response.data : {});
}

function retrieveEventsFailure(context, { response, error }) {
	let errorRes = response ? response.statusText : error;
	// If we have an error, but no status text, set a plceholder so the UI responds apporpriately
	if (!errorRes) {
		errorRes = 'Server Error';
	}
	context.dispatch('EVENT_REQUEST_FAILED', errorRes);
}

export default (config) => {
	const url = get(config, 'endpoints.missingPersons.events.retrieve');

	const serviceName = 'retrieveEvents';

	return {
		serviceName,
		authenticate: true,
		config: {
			method: 'get',
			url,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		},
		beforeRequest: retrieveEventsBeforeRequest,
		onSuccess: retrieveEventsSuccess,
		onError: retrieveEventsFailure,
		onFailed: retrieveEventsFailure,
		onTimeout: retrieveEventsFailure,
		onFatal: retrieveEventsFailure,
	};
};
