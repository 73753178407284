import get from 'lodash/get';
import showNotification from '../../../actions/show-notification';

function beforeRequest(actionContext) {
	actionContext.dispatch('UPLOAD_DOCUMENT_START');
}

function onSuccess(actionContext, { response: { data } }) {
	actionContext.dispatch('UPLOAD_DOCUMENT_FINISH', data);
}

function onFailed(actionContext) {
	actionContext.executeAction(showNotification, {
		heading: 'Error Uploading Document',
		message: 'Unable to upload document. Please try again',
		color: 'danger',
	});

	actionContext.dispatch('UPLOAD_DOCUMENT_FAILED', 'Error uploading to S3');
}

export default (config) => ({
	serviceName: 'uploadDocument',
	config: {
		method: 'PUT',
		baseUrl: '',
		url: get(config, 'endpoints.missingPersons.searches.uploadDocument'),
	},
	beforeRequest,
	onSuccess,
	onFailed,
	onError: onFailed,
	onTimeout: onFailed,
	onFatal: onFailed,
});
