import CommonStore from '@audacious/web-common/fluxible/CommonStore';

const initialState = {
	configs: null,
	isLoading: false,
	error: null,
};

class ConfigSettingsStore extends CommonStore {
	constructor(dispatcher) {
		super(dispatcher, initialState);
	}

	configRequestStart() {
		this.setState({
			isLoading: true,
			error: null,
		});
	}

	configRequestFinish(configs) {
		this.setState({
			configs,
			isLoading: false,
		});
	}

	configRequestFail(error) {
		this.setState({
			isLoading: false,
			error,
		});
	}

	getReportGenerationDelay() {
		return this.state.configs?.reports?.reportGenerationDelay;
	}

	getEnableCMTIntegration() {
		return this.state.configs?.tenantSettings?.enableCMTIntegration ?? false;
	}

	isLoading() {
		return this.state.isLoading;
	}

	getConfigSettings() {
		const { isLoading } = this.state;
		const reportGenerationDelay = this.state.configs?.reports?.reportGenerationDelay;
		const enableCMTIntegration = this.state.configs?.tenantSettings?.enableCMTIntegration;

		return { isLoading, reportGenerationDelay, enableCMTIntegration };
	}
}

ConfigSettingsStore.storeName = 'ConfigSettingsStore';
ConfigSettingsStore.handlers = {
	CONFIG_REQUEST_START: 'configRequestStart',
	CONFIG_REQUEST_FINISH: 'configRequestFinish',
	CONFIG_REQUEST_FAILED: 'configRequestFail',
	LOGOUT: 'resetState',
};

export default ConfigSettingsStore;
