import isNil from 'lodash/isNil';
import uploadAndFinalize from './upload-and-finalize';
import showNotification from './show-notification';

export default async function updateSearch(context, { data, csvData }) {
	const searchStore = context.getStore('SearchStore');
	const { service } = context;
	const { title, eventId, description, id } = data;
	const successMessage = 'Search List Updated Successfully';

	service.updateSearch(
		{
			data: {
				title,
				eventId,
				description,
			},
			options: {
				searchId: id,
			},
		},
		() => {
			const { error } = searchStore.getState();

			if (isNil(error) && !isNil(csvData)) {
				uploadAndFinalize(context, { id, csvData, successMessage });
			} else if (isNil(error)) {
				showNotification(context, {
					heading: successMessage,
					color: 'success',
				});
			}
		},
	);
}
