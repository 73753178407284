import get from 'lodash/get';
import forEach from 'lodash/forEach';

import retrieveSearchesPoller from '../../../common/retrieve-searches-poller';

function beforeRequest(actionContext) {
	const { service } = actionContext;

	actionContext.dispatch('SEARCH_REQUEST_START');

	retrieveSearchesPoller.start(service);
}

function onSuccess(actionContext, { response: { data } }) {
	const { downloadReport } = actionContext.service;

	const ConfigSettingsStore = actionContext.getStore('ConfigSettingsStore');
	const configSettings = ConfigSettingsStore.getConfigSettings();

	const searchStore = actionContext.getStore('SearchStore');
	const searchState = searchStore.getState();
	const { searches } = searchState;

	actionContext.dispatch('SEARCH_REQUEST_FINISH', { data, configSettings });

	// Download reports for the searches once the report generation is completed.

	if (configSettings.enableCMTIntegration) {
		forEach(searches, (search) => {
			if (
				search.isGeneratingReport &&
				search.generateReportStartedAt <=
					Date.parse(search.completedExecution?.reportGeneratedAt)
			) {
				const searchId = search?.id;
				const searchName = search?.title;
				const reportTime = search?.completedExecution?.formatted?.reportGeneratedAt;
				downloadReport({ options: { searchId, searchName, reportTime } });
				actionContext.dispatch('RESET_REPORT_GENERATION_STATE', search.id);
			}
		});
	}
}

function onFailed(actionContext, { response, error }) {
	let errorRes = response ? response.statusText : error;
	// If we have an error, but no status text, set a plceholder so the UI responds apporpriately
	if (!errorRes) {
		errorRes = 'Server Error';
	}
	actionContext.dispatch('SEARCH_REQUEST_FAILED', errorRes);

	retrieveSearchesPoller.stop();
}

export default (config) => ({
	serviceName: 'retrieveSearches',
	config: {
		method: 'GET',
		url: get(config, 'endpoints.missingPersons.searches.retrieve'),
	},
	beforeRequest,
	onSuccess,
	onFailed,
	onError: onFailed,
	onTimeout: onFailed,
	onFatal: onFailed,
});
