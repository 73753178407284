import get from 'lodash/get';
import showNotification from '../../../actions/show-notification';

function beforeRequest(actionContext, { options }) {
	const { searchId } = options;

	actionContext.dispatch('GENERATE_REPORT_START', searchId);
}

function onFailed(actionContext, { options }) {
	const { searchId } = options;

	actionContext.executeAction(showNotification, {
		heading: 'Report Generate Error',
		message: 'Unable to generate report. Please try again.',
		color: 'danger',
	});

	actionContext.dispatch('GENERATE_REPORT_FAILED', searchId);
}

export default (config) => ({
	serviceName: 'generateReport',
	config: {
		method: 'POST',
		url: get(config, 'endpoints.missingPersons.reports.generate'),
	},
	beforeRequest,
	onFailed,
	onError: onFailed,
	onTimeout: onFailed,
	onFatal: onFailed,
});
