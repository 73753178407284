import CommonStore from '@audacious/web-common/fluxible/CommonStore';

const initialState = {
	events: [],
	isLoading: false,
	error: null,
};

class EventStore extends CommonStore {
	constructor(dispatcher) {
		super(dispatcher, initialState);
	}

	eventRequestStart() {
		this.setState({
			isLoading: true,
			error: null,
		});
	}

	eventRequestFinish(events) {
		this.setState({
			events,
			isLoading: false,
		});
	}

	eventRequestFail(error) {
		this.setState({
			isLoading: false,
			error,
		});
	}
}

EventStore.storeName = 'EventStore';
EventStore.handlers = {
	EVENT_REQUEST_START: 'eventRequestStart',
	EVENT_REQUEST_FINISH: 'eventRequestFinish',
	EVENT_REQUEST_FAILED: 'eventRequestFail',
	LOGOUT: 'resetState',
};

export default EventStore;
