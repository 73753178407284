export default function showNotification(context, payload) {
	const {
		notifications: {
			alert: { set, clear },
		},
	} = context;

	set(payload);

	setTimeout(clear, 3000);
}
