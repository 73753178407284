import isNil from 'lodash/isNil';
import showNotification from './show-notification';

export default async function uploadAndFinalize(context, { id, csvData, successMessage }) {
	const searchStore = context.getStore('SearchStore');
	const { service } = context;
	service.createAppendage({ options: { searchId: id } }, () => {
		const searchStateDoc = searchStore.getState();
		const { presignedUrl, error: searchError } = searchStateDoc;
		if (isNil(searchError)) {
			service.uploadDocument(
				{
					data: csvData,
					options: { presignedUrl },
				},
				() => {
					const finalizeDocumentState = searchStore.getState();
					const { error: finalizeError, appendageId } = finalizeDocumentState;
					if (isNil(finalizeError)) {
						service.finalizeUpload(
							{
								options: { searchId: id, appendageId },
							},
							() => {
								showNotification(context, {
									heading: successMessage,
									color: 'success',
								});
							},
						);
					}
				},
			);
		}
	});
}
