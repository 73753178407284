class RetrieveSearchesPoller {
	constructor() {
		this.intervalFunction = null;
	}

	start(service) {
		if (!this.intervalFunction) {
			this.intervalFunction = setInterval(() => {
				service.retrieveSearches();
			}, 5000);
		}
	}

	stop() {
		clearInterval(this.intervalFunction);
		this.intervalFunction = null;
	}
}

const retrieveSearchesPoller = new RetrieveSearchesPoller();

export default retrieveSearchesPoller;
